import { wrapMutation } from '@fresh-stack/frontend-commons';
import { Result, isError, isSuccess } from '@fresh-stack/fullstack-commons';
import { LoadingButton } from '@mui/lab';
import { Alert, Stack, TextField } from '@mui/material';
import React from 'react';
import { trpc } from '../../utils';

export const InviteToolingUser = () => {
  const [inviteeEmail, setInviteeEmail] = React.useState('');
  const { mutateAsync, isLoading } = wrapMutation(
    trpc.auth0.inviteToolingUser.useMutation(),
  );
  const [status, setStatus] = React.useState<
    Result<{ readonly id: string }, string> | undefined
  >();
  const allowed =
    inviteeEmail.endsWith('@block.green') ||
    inviteeEmail.endsWith('@getribon.com');

  const onInvite = async () => {
    if (allowed) {
      setStatus(undefined);
      const result = await mutateAsync(inviteeEmail);
      setStatus(result);
      if (isSuccess(result)) setInviteeEmail('');
    }
  };

  return (
    <Stack>
      <Stack direction={'row'} spacing={1} alignItems={'center'}>
        <TextField
          sx={{ width: 400 }}
          value={inviteeEmail}
          label={'Email'}
          placeholder="user@block.green"
          onChange={(value) => setInviteeEmail(value.target.value)}
        ></TextField>
        <LoadingButton
          sx={{ height: 40 }}
          onClick={onInvite}
          loading={isLoading}
          disabled={!allowed}
          variant="contained"
        >
          Invite
        </LoadingButton>
      </Stack>
      {!allowed && !!inviteeEmail.length && (
        <Alert severity="warning">
          Email must belong to block.green or getribon.com domain.
        </Alert>
      )}
      {status && isError(status) && (
        <Alert severity="error">
          {'There was an issue sending the invite:' +
            status.left.unpackedError.message}
        </Alert>
      )}
      {status && isSuccess(status) && (
        <Alert severity="success">Invitation has been sent successfully.</Alert>
      )}
    </Stack>
  );
};
