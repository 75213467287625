import { head } from '@fresh-stack/fullstack-commons';
import { Avatar, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import React from 'react';
import { useLogout } from '@fresh-stack/frontend-commons';
import { useUserContext } from '../../app/hooks/userContext';
import { appColors } from '../../theme';

export const NavBarAvatar = () => {
  const { logout } = useLogout();
  const { user: email } = useUserContext();

  const [anchorElAvatarMenu, setAnchorElAvatarMenu] =
    React.useState<null | HTMLElement>(null);

  const handleAvatarMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElAvatarMenu(event.currentTarget);
  };

  const handleCloseAvatarMenu = () => {
    setAnchorElAvatarMenu(null);
  };

  return (
    <>
      <Typography variant="caption">{email}</Typography>

      <IconButton onClick={handleAvatarMenu}>
        <Avatar
          sx={{ bgcolor: appColors.primary }}
          alt="Account"
          variant="circular"
        >
          {head(email) ?? ''}
        </Avatar>
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorElAvatarMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{ mt: 6 }}
        open={Boolean(anchorElAvatarMenu)}
        onClose={handleCloseAvatarMenu}
      >
        <MenuItem key="signout" sx={{ width: 200 }} onClick={logout}>
          <Typography variant="body2">Sign out</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};
