import { useLogout } from '../hooks/useLogout';
import { Loader } from './Loader';

export const Logout = () => {
  const { logout } = useLogout();

  logout();

  return <Loader />;
};
