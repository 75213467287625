import { Logout } from '@fresh-stack/frontend-commons';
import { Outlet, createBrowserRouter } from 'react-router-dom';
import { ROUTES, getPathElements } from '.';
import { AppLayout } from '../../AppLayout';
import { ErrorPage } from '../../components';

export const buildAppRouter = () =>
  createBrowserRouter([
    {
      element: <AppLayout />,
      errorElement: (
        <AppLayout>
          <ErrorPage
            title="Oops"
            status="404"
            message="This page does not exist"
          />
        </AppLayout>
      ),
      children: [
        {
          element: <Outlet />,
          children: getPathElements(),
        },
      ],
    },
    {
      path: ROUTES.Logout,
      element: <Logout />,
    },
  ]);
