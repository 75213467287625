export const ROUTES = {
  Home: '/',
  Logout: '/logout',
  UserManagement: {
    AppUsers: '/user-mgmt/users',
    AppCompanies: '/user-mgmt/companies',
    ApprovedDomains: '/user-mgmt/signup/domains',
    Company: '/user-mgmt/company/:id',
    Notifications: '/user-mgmt/notifications',
  },
  GenerateData: {
    MossTransactions: '/data/moss-transactions',
  },
  ToolingManagement: {
    Users: '/mgmt/users',
  },
  Storybook: '/storybook/index.html?path=',
  Demo: {
    Firestore: '/demo/firestore',
    Email: '/demo/email',
    ErrorAlerts: '/demo/alerts',
    AI: '/demo/ai',
  },
  BankAccounts: '/actions/bank-accounts',
  PlaidTransactions: '/actions/plaid-transactions',
};
