import { wrapMutation } from '@fresh-stack/frontend-commons';
import { Result, isError, isSuccess } from '@fresh-stack/fullstack-commons';
import { LoadingButton } from '@mui/lab';
import { Alert, Stack, TextField } from '@mui/material';
import React from 'react';
import { useUserContext } from '../../app/hooks/userContext';
import { trpc } from '../../utils';

export const EmailDemo = () => {
  const [firstname, setFirstname] = React.useState('');
  const [lastname, setLastname] = React.useState('');
  const { user } = useUserContext();

  const { mutateAsync, isLoading } = wrapMutation(
    trpc.email.sendDemoEmail.useMutation(),
  );
  const [status, setStatus] = React.useState<
    Result<{ readonly status: number }, string> | undefined
  >();

  const onSend = async () => {
    setStatus(undefined);
    const result = await mutateAsync({ firstname, lastname });
    setStatus(result);
    if (isSuccess(result)) {
      setFirstname('');
      setLastname('');
    }
  };

  const allowed = !!firstname && !!lastname;

  return (
    <Stack spacing={2}>
      <Alert severity="info">
        This form will send you an email using a Mailgun template filled in with
        the values you provide for the first and last names.
      </Alert>
      <Stack direction={'row'} spacing={1} alignItems={'center'}>
        <TextField
          sx={{ width: 400 }}
          value={firstname}
          label={'First name'}
          placeholder="Dwight"
          onChange={(value) => setFirstname(value.target.value)}
        ></TextField>
        <TextField
          sx={{ width: 400 }}
          value={lastname}
          label={'Last name'}
          placeholder="Schrute"
          onChange={(value) => setLastname(value.target.value)}
        ></TextField>
        <LoadingButton
          sx={{ height: 40 }}
          onClick={onSend}
          loading={isLoading}
          disabled={!allowed}
          variant="contained"
        >
          Send email
        </LoadingButton>
      </Stack>
      {status && isError(status) && (
        <Alert severity="error">
          {'There was an issue sending the email:' +
            status.left.unpackedError.message}
        </Alert>
      )}
      {status && isSuccess(status) && (
        <Alert severity="success">Email sent to {user}!</Alert>
      )}
    </Stack>
  );
};
