import React, { useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { httpLink } from '@trpc/client';
import { trpc } from '../utils';

export const TrpcProvider = ({
  children,
}: {
  readonly children: readonly React.ReactElement[];
}) => {
  const [queryClient] = useState(() => new QueryClient());

  const { getAccessTokenSilently } = useAuth0();
  const [trpcClient] = useState(() =>
    trpc.createClient({
      links: [
        httpLink({
          url: import.meta.env.VITE_TOOLING_API_URL,
          headers: async () => {
            const token = await getAccessTokenSilently();

            return {
              authorization: token ? `Bearer ${token}` : '',
            };
          },
        }),
      ],
    }),
  );
  return (
    <QueryClientProvider client={queryClient}>
      <trpc.Provider client={trpcClient} queryClient={queryClient}>
        {children}
      </trpc.Provider>
    </QueryClientProvider>
  );
};
