import './assets/App.css';

import { createTheme } from '@mui/material';

export const appColors = {
  primary: '#3e4f52',
  secondary: '#F0EBE3',
  secondaryLighter: '#fffdfaDD',
};

export const FONTS = {
  lato: 'Lato',
  latoBold: 'LatoBold',
  inter: 'Inter',
  interBold: 'InterBold',
  montserratBold: 'MontserratBold',
  montserratExtraBold: 'MontserratExtraBold',
};

const bodyFont = FONTS.inter;

const buildGlobalTheme = () => {
  const theme = createTheme({
    palette: {
      primary: {
        main: appColors.primary,
      },
      secondary: {
        main: appColors.secondary,
      },
      // Used by `getContrastText()` to maximize the contrast between
      // the background and the text.
      contrastThreshold: 3,
      // Used by the functions below to shift a color's luminance by approximately
      // two indexes within its tonal palette.
      // E.g., shift from Red 500 to Red 300 or Red 700.
      tonalOffset: 0.2,
    },
    components: {
      MuiToolbar: {
        styleOverrides: {
          dense: {
            height: 40,
            minHeight: 40,
          },
        },
      },
    },
    typography: {
      fontFamily: bodyFont,
    },
  });

  return theme;
};

export const THEME_GLOBAL = buildGlobalTheme();
