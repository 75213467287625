import { ResNotificationDto } from '@fresh-stack/core-services';
import {
  ConfirmationModal,
  NotificationCard,
  wrapMutation,
  wrapQuery,
} from '@fresh-stack/frontend-commons';
import { head, isError } from '@fresh-stack/fullstack-commons';
import DeleteIcon from '@mui/icons-material/Delete';
import { LoadingButton } from '@mui/lab';
import { Box, IconButton, Stack, TextField, Typography } from '@mui/material';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { trpc } from '../../utils';

type NotificationDto = {
  readonly createdAt: string;
  readonly isDeleted: boolean;
  readonly id: string;
  readonly title: string;
  readonly description: string;
  readonly severity: string;
};

export const ReleaseNotificationsPage = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [notificationToCreate, setNotificationToCreate] = useState<
    undefined | { readonly title: string; readonly description: string }
  >();

  const [notificationToDelete, setNotificationToDelete] = useState<
    undefined | string
  >();

  const { mutateAsync: createNotification, isLoading: isSaving } = wrapMutation(
    trpc.notifications.createGlobalNotification.useMutation(),
  );

  const { mutateAsync: deleteNotification, isLoading: isDeleting } =
    wrapMutation(trpc.notifications.deleteGlobalNotification.useMutation());

  const { result: maybeNotifications, refetch } = wrapQuery(
    trpc.notifications.getGlobalNotifications.useQuery(undefined, {
      refetchOnWindowFocus: false,
    }),
  );

  const notifications =
    !maybeNotifications || isError(maybeNotifications)
      ? []
      : maybeNotifications.right;

  const notificationColumns: GridColDef<NotificationDto>[] = [
    ...Object.keys(head(notifications) ?? {}).map((key) => ({
      field: key,
      headerName: key.charAt(0).toUpperCase() + key.slice(1),
      flex: 1,
    })),
    {
      field: 'delete',
      headerName: 'Delete',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return params.row.isDeleted ? null : (
          <Box display={'flex'} pl={1}>
            <IconButton
              onClick={() => setNotificationToDelete(params.row.id)}
              disabled={isSaving}
            >
              <DeleteIcon fontSize="small" color="error" />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  const [title, setTitle] = useState<string>('Notification title');
  const [message, setMessage] = useState<string>(
    'This is the notification message.',
  );

  const previewNotification: ResNotificationDto = {
    id: '',
    title: title,
    severity: 'Success',
    description: message,
    createdAt: new Date(),
  };

  const onSave = async ({
    title,
    description,
  }: {
    readonly title: string;
    readonly description: string;
  }) => {
    const cleanTitle = title.trim();
    const cleanDescription = description.trim();

    setNotificationToCreate(undefined);

    if (cleanTitle && cleanDescription) {
      const result = await createNotification({
        title: cleanTitle,
        description: cleanDescription,
      });
      refetch();

      if (isError(result)) {
        enqueueSnackbar({
          message: 'There was an issue creating the notification.',
          variant: 'error',
        });
      } else {
        setTitle('');
        setMessage('');
        enqueueSnackbar({
          message: `Notification ${result.right.notificationId} created and sent to all users (${result.right.recipients}).`,
          variant: 'success',
        });
      }
    } else {
      enqueueSnackbar({
        message: 'Title and description must be non empty.',
        variant: 'error',
      });
    }
  };

  const onDelete = async (id: string) => {
    setNotificationToDelete(undefined);

    const result = await deleteNotification(id);
    refetch();

    if (isError(result)) {
      enqueueSnackbar({
        message: 'There was an issue creating the notification.',
        variant: 'error',
      });
    } else {
      setTitle('');
      setMessage('');
      enqueueSnackbar({
        message: `Notification ${id} has been deleted.`,
        variant: 'success',
      });
    }
  };

  return (
    <Stack width={'100%'} spacing={2}>
      <Typography variant="h4">Global notifications</Typography>
      <TextField
        value={title}
        label={'Title'}
        sx={{ maxWidth: 600 }}
        fullWidth
        onChange={(value) => setTitle(value.target.value)}
      ></TextField>
      <TextField
        value={message}
        label={'Message'}
        sx={{ maxWidth: 600 }}
        fullWidth
        multiline
        rows={5}
        onChange={(value) => setMessage(value.target.value)}
      ></TextField>

      <Box width={400} gap={2}>
        <Typography variant="h5">Preview </Typography>
        <NotificationCard
          notification={previewNotification}
          onMarkAsRead={console.log}
          disabled={false}
        ></NotificationCard>
      </Box>
      <LoadingButton
        variant="contained"
        loading={isSaving || isDeleting}
        disabled={!message || !title}
        onClick={() => setNotificationToCreate({ title, description: message })}
      >
        Create global notification
      </LoadingButton>

      {!!notifications.length && (
        <DataGridPro
          sx={{
            boxShadow: 4,
            padding: 1,
            minWidth: 800,
            maxWidth: 1000,
            maxHeight: '50vh',
          }}
          rows={notifications}
          columns={notificationColumns}
          initialState={{
            pagination: { paginationModel: { pageSize: 1000 } },
          }}
          pagination
        />
      )}
      {notificationToCreate && (
        <ConfirmationModal
          question={`Are you sure you want to create this notification for all users?`}
          handleApprove={() => {
            onSave(notificationToCreate);
          }}
          handleCancel={() => {
            setNotificationToCreate(undefined);
          }}
        />
      )}
      {notificationToDelete && (
        <ConfirmationModal
          question={`Are you sure you want to delete this notification?`}
          handleApprove={() => {
            onDelete(notificationToDelete);
          }}
          handleCancel={() => {
            setNotificationToDelete(undefined);
          }}
        />
      )}
    </Stack>
  );
};
