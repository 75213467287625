import { ResNotificationDto } from '@fresh-stack/router/types';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import DoneIcon from '@mui/icons-material/Done';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import WarningIcon from '@mui/icons-material/Warning';
import {
  Box,
  Card,
  CardContent,
  Grid,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { CustomHoverIconButton } from './banks';
import { appColors } from './theme';

export const NotificationCard = ({
  notification,
  onMarkAsRead,
  disabled,
}: {
  readonly notification: ResNotificationDto;
  readonly onMarkAsRead: () => void;
  readonly disabled?: boolean;
}) => {
  const getIcon = () => {
    switch (notification.severity) {
      case 'Error':
        return <ErrorIcon color="error" fontSize="small" />;
      case 'Success':
        return <AssignmentTurnedInIcon color="success" fontSize="small" />;
      case 'Info':
        return <InfoIcon color="info" fontSize="small" />;
      case 'Warning':
        return <WarningIcon color="warning" fontSize="small" />;
    }
  };

  return (
    <Card
      sx={{
        bgcolor: appColors.secondaryLighter,
        boxShadow: 3,
        padding: 0,
      }}
    >
      <CardContent>
        <Grid container item justifyContent={'space-between'}>
          <Box display="flex" alignContent={'center'}>
            {getIcon()}
            <Typography variant="caption" ml={1}>
              {new Date(notification.createdAt).toLocaleDateString()}{' '}
              {new Date(notification.createdAt).toLocaleTimeString()}
            </Typography>
          </Box>
          {notification.readtAt ? (
            <DoneIcon fontSize="small" color="success" />
          ) : (
            <Tooltip title={'Mark as read.'}>
              <CustomHoverIconButton
                defaultColor="silver"
                hoverColor="green"
                onClick={onMarkAsRead}
                disabled={disabled}
                size="small"
              >
                <ThumbUpIcon fontSize="small" />
              </CustomHoverIconButton>
            </Tooltip>
          )}
        </Grid>
        <Stack direction={'row'} spacing={1} alignItems={'center'}>
          <Typography variant="subtitle1" fontWeight={500} gutterBottom>
            {notification.title}
          </Typography>
        </Stack>

        <Typography variant="body1">{notification.description}</Typography>
      </CardContent>
    </Card>
  );
};
