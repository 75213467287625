import { SqlDtoCompany } from '@fresh-stack/core-services';
import { wrapQuery } from '@fresh-stack/frontend-commons';
import { head, isError } from '@fresh-stack/fullstack-commons';
import {
  Backdrop,
  Box,
  CircularProgress,
  Stack,
  Typography,
} from '@mui/material';
import {
  DataGridPro,
  GridColDef,
  GridToolbarContainer,
  GridToolbarExport,
} from '@mui/x-data-grid-pro';
import React from 'react';
import { trpc } from '../../utils';

const companyColumns: readonly GridColDef<SqlDtoCompany>[] = [
  { field: 'id', headerName: 'Id', width: 100 },
  { field: 'legalName', headerName: 'Legal name', flex: 1 },
  { field: 'createdAt', headerName: 'Created at', width: 200 },
];
export const PlaidTransactionsPage = () => {
  const [companyId, setCompanyId] = React.useState<string | undefined>();

  const { result: maybeCompanies, isFetching } = wrapQuery(
    trpc.users.getCompanies.useQuery(undefined, {
      refetchOnWindowFocus: false,
    }),
  );

  const { result: maybeTransactions, isFetching: isLoadingTransactions } =
    wrapQuery(
      trpc.actions.getPlaidTransactions.useQuery(
        {
          companyId: companyId ?? '',
          skip: 0,
          take: 1000,
        },

        { enabled: !!companyId, refetchOnWindowFocus: false },
      ),
    );

  const companies =
    !maybeCompanies || isError(maybeCompanies) ? [] : maybeCompanies.right;

  const selectedCompany = companies.find((x) => x.id === companyId);

  const transactions =
    !maybeTransactions || isError(maybeTransactions)
      ? []
      : maybeTransactions.right;

  const transactionColumns = Object.keys(head(transactions) ?? {}).map(
    (key) => ({
      field: key,
      headerName: key.charAt(0).toUpperCase() + key.slice(1),
      width: 150,
      editable: true,
    }),
  );

  return isFetching ? (
    <Backdrop
      sx={{ color: 'white', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isFetching}
    >
      <CircularProgress color="secondary" />
    </Backdrop>
  ) : (
    <Stack width={'100%'} spacing={2}>
      <Typography variant="h4">Plaid transactions</Typography>
      <Typography>
        Select a company to load transactions (we currently load at most 1000
        transactions only).
      </Typography>
      <Box sx={{ width: '100%', maxHeight: '55vh' }}>
        <DataGridPro
          sx={{
            boxShadow: 4,
            padding: 1,
            maxHeight: 300,
            width: 800,
          }}
          loading={isFetching}
          rows={companies}
          columns={companyColumns}
          initialState={{
            pagination: { paginationModel: { pageSize: 100 } },
          }}
          pagination
          pageSizeOptions={[100, 200]}
          onRowClick={(params) => setCompanyId(params.id.toString())}
        />
      </Box>
      {isLoadingTransactions ? <CircularProgress sx={{ height: 40 }} /> : null}
      {selectedCompany ? (
        <Box>
          <Typography>
            {selectedCompany.legalName} - {transactions.length} transactions
            loaded
          </Typography>
          {!!transactions.length && (
            <DataGridPro
              sx={{
                boxShadow: 4,
                padding: 1,
                maxWidth: 1000,
                maxHeight: '50vh',
              }}
              slots={{ toolbar: CustomToolbar }}
              rows={transactions}
              columns={transactionColumns}
              initialState={{
                pagination: { paginationModel: { pageSize: 1000 } },
              }}
              pagination
            />
          )}
        </Box>
      ) : null}
    </Stack>
  );
};

const CustomToolbar = () => {
  return (
    <GridToolbarContainer>
      <Box sx={{ flexGrow: 1 }} />
      <Box pr={2}>
        <GridToolbarExport />
      </Box>
    </GridToolbarContainer>
  );
};
