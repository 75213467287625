import { Box, Typography } from '@mui/material';

interface ErrorPageProps {
  readonly title?: string;
  readonly status?: string;
  readonly message?: string | JSX.Element;
  readonly fullHeight?: boolean;
}

export const ErrorPage = ({ title, status, message }: ErrorPageProps) => {
  return (
    <Box>
      <Typography variant="h3"> Error page </Typography>
      <Typography variant="h3"> {title} </Typography>
      <Typography variant="h4"> {status} </Typography>
      {message}
    </Box>
  );
};
