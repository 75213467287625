import {
  getPayload,
  inspectError,
  isSuccess,
  pipe,
} from '@fresh-stack/fullstack-commons';
import { LoadingButton } from '@mui/lab';
import { Alert, Box, Stack, Typography } from '@mui/material';
import React from 'react';
import { trpc } from '../../utils';
import { wrapMutation, wrapQuery } from '@fresh-stack/frontend-commons';

export const FirestoreDemo = () => {
  const [updateError, setUpdateError] = React.useState<string | undefined>();
  const { result: document, refetch } = wrapQuery(
    trpc.firestoreDemo.getDemoDocument.useQuery(undefined, {
      refetchOnWindowFocus: false,
      refetchInterval: 15000,
    }),
  );

  const { mutateAsync } = wrapMutation(
    trpc.firestoreDemo.updateDemoDocument.useMutation(),
  );

  const onCreate = async () => {
    setUpdateError(undefined);
    const result = await mutateAsync();
    refetch();
    pipe(
      result,
      inspectError(() =>
        setUpdateError('There was an issue creating the document'),
      ),
    );
  };

  const json = document
    ? isSuccess(document)
      ? getPayload(document) ?? { payload: 'nothing yet' }
      : document
    : { payload: 'nothing yet' };

  return (
    <Stack width={'100%'} spacing={2}>
      <Typography variant="subtitle1">FIRESTORE DEMO</Typography>
      <LoadingButton
        onClick={onCreate}
        variant="contained"
        sx={{ width: 150, fontWeight: 700, fontSize: 20 }}
      >
        Click me
      </LoadingButton>

      {updateError && <Alert severity="error" title={updateError} />}

      <Typography>This shows the last click from all tooling users:</Typography>
      <Box width={'100%'}>
        <pre>{JSON.stringify(json, null, 2)}</pre>
      </Box>
    </Stack>
  );
};
