import React from 'react';
import { useLocation } from 'react-router-dom';

import { NavBarContainerItem } from '@fresh-stack/frontend-commons';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { ListItemButton, Typography } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import { MenuLeafItem } from './MenuLeafItem';

export const TopLevelMenuItem = ({
  item,
}: {
  readonly item: NavBarContainerItem;
}) => {
  const [open, setOpen] = React.useState(false);
  const location = useLocation();

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItemButton onClick={handleClick}>
        <ListItemText>
          <Typography
            color={item.path === location.pathname ? 'primary' : 'default'}
          >
            {item.name}
          </Typography>
        </ListItemText>
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding sx={{ ml: 2 }}>
          {item.children?.map((child) =>
            child.kind === 'leaf' ? (
              <MenuLeafItem key={'mli' + item.name + child.path} item={child} />
            ) : null,
          )}
        </List>
      </Collapse>
    </>
  );
};
