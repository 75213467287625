import { wrapQuery } from '@fresh-stack/frontend-commons';
import { isError } from '@fresh-stack/fullstack-commons';
import { Alert, Box, CircularProgress } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { trpc } from '../../utils';

export const AppUsers = () => {
  const { result: users, isFetching } = wrapQuery(
    trpc.users.getUsers.useQuery(),
  );

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>Created at</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Cellphone</TableCell>
              <TableCell>User name</TableCell>
              <TableCell>Company</TableCell>
              <TableCell>Role</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!users || isFetching ? (
              <CircularProgress />
            ) : isError(users) ? (
              <Alert severity="error">
                There was an issue loading the users.
              </Alert>
            ) : (
              users.right.map((row) => (
                <TableRow
                  key={row.userId}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.userId}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.createdAt}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.email}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.cellphoneNumber ?? '-'}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.firstName} {row.lastName}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.companyName ?? '-'}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.userType ?? '-'}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
