import {
  Box,
  Button,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import {
  DateRange,
  DesktopDateRangePicker,
  PickersShortcutsItem,
} from '@mui/x-date-pickers-pro';
import { NonEmptyDateRange } from '@mui/x-date-pickers-pro/internals/models';
import { parse, startOfMonth, subMonths, subYears } from 'date-fns';
import { useState } from 'react';
import * as XLSX from 'xlsx';
import { generateMossCsv } from './utils';

const shortcutsItems: PickersShortcutsItem<DateRange<Date>>[] = [
  {
    label: 'Month to date',
    getValue: () => {
      const today = new Date();
      return [startOfMonth(today), today];
    },
  },
  {
    label: 'Last 3 months',
    getValue: () => {
      const today = new Date();
      return [startOfMonth(subMonths(today, 2)), today];
    },
  },
  {
    label: 'Last 6 months',
    getValue: () => {
      const today = new Date();
      return [startOfMonth(subMonths(today, 5)), today];
    },
  },
  {
    label: 'Last 12 months',
    getValue: () => {
      const today = new Date();
      return [startOfMonth(subMonths(today, 11)), today];
    },
  },
  {
    label: '2023',
    getValue: () => {
      return [
        parse('01-01-2023', 'dd-MM-yyyy', new Date()),
        parse('31-12-2023', 'dd-MM-yyyy', new Date()),
      ];
    },
  },
];

export const MossTransactionFilePage = () => {
  const [range, setRange] = useState<NonEmptyDateRange<Date>>([
    startOfMonth(subMonths(new Date(), 11)),
    new Date(),
  ]);

  const [currency, setCurrency] = useState<string>('EUR');

  return (
    <Stack width={'100%'} spacing={2}>
      <Typography variant="subtitle1">Generate moss file</Typography>
      <Box display={'flex'} alignItems={'center'} gap={2}>
        <DesktopDateRangePicker
          slotProps={{
            shortcuts: {
              items: shortcutsItems,
            },
            actionBar: { actions: [] },
          }}
          value={range}
          maxDate={new Date()}
          minDate={subYears(new Date(), 2)}
          onChange={(x) => {
            if (x[0] && x[1]) setRange([x[0], x[1]]);
          }}
        />
        <Select
          labelId="select-currency-label"
          id="select-currency"
          value={currency}
          sx={{ width: 100 }}
          size="small"
          onChange={(change) => {
            if (change.target.value)
              setCurrency(change.target.value.toString());
          }}
        >
          {['EUR', 'USD', 'CHF', 'GBP'].map((x) => (
            <MenuItem key={'mi-cry-' + x} value={x}>
              {x}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Button
        onClick={() => {
          const csv = generateMossCsv(range, currency);
          const workbook = XLSX.utils.book_new(csv);

          XLSX.writeFile(workbook, 'test.csv');
        }}
        variant="contained"
      >
        Generate
      </Button>
    </Stack>
  );
};
