import { useLocation, useNavigate } from 'react-router-dom';

import { Typography } from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { NavBarLeafItem } from '@fresh-stack/frontend-commons';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
export const MenuLeafItem = ({ item }: { readonly item: NavBarLeafItem }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const isSelected = item.path === location.pathname;
  const icon = isSelected ? item.activeIcon : item.passiveIcon;
  return (
    <ListItemButton
      key={'key-lib-mi-' + item.name}
      sx={{
        minWidth: 150,
        '&:hover': { textDecoration: 'underline' },
      }}
      onClick={() => {
        if (item.path) {
          if (item.handling === 'new-tab') {
            window.open(`${window.location.origin}${item.path}`);
          } else navigate(item.path);
        }
      }}
    >
      {icon && <ListItemIcon sx={{ mr: -3.5 }}>{icon}</ListItemIcon>}
      <ListItemText>
        <Typography
          color={item.path === location.pathname ? 'primary' : 'default'}
        >
          {item.name}
        </Typography>
      </ListItemText>
      {item.handling === 'new-tab' && <OpenInNewIcon />}
    </ListItemButton>
  );
};
