import z from 'zod';
import { Alpha2Code } from 'i18n-iso-countries';
import { CountryCode } from 'plaid';
import { upperFirst } from '../lib/lodash-utils';

export const ENUM_NAME_SUPPORTED_COUNTRY_ENUM = 'supported_country_enum';
export enum SqlCountryCodeEnum {
  US = 'US',
  GB = 'GB',
  ES = 'ES',
  NL = 'NL',
  FR = 'FR',
  IE = 'IE',
  CA = 'CA',
  DE = 'DE',
  IT = 'IT',
  PL = 'PL',
  DK = 'DK',
  NO = 'NO',
  SE = 'SE',
  EE = 'EE',
  LT = 'LT',
  LV = 'LV',
  PT = 'PT',
  BE = 'BE',
}
// these should always be in sync
export const SchemaCountryAlpha2Code = z.union([
  z.literal('US'),
  z.literal('GB'),
  z.literal('ES'),
  z.literal('NL'),
  z.literal('FR'),
  z.literal('IE'),
  z.literal('CA'),
  z.literal('DE'),
  z.literal('IT'),
  z.literal('PL'),
  z.literal('DK'),
  z.literal('NO'),
  z.literal('SE'),
  z.literal('EE'),
  z.literal('LT'),
  z.literal('LV'),
  z.literal('PT'),
  z.literal('BE'),
]);

export type SupportedCountryAlpha2Subset<T extends Alpha2Code> = T;

export type SupportedCountryAlpha2Code = SupportedCountryAlpha2Subset<
  z.infer<typeof SchemaCountryAlpha2Code>
>;

export const isSupportedCountryAlpha2Code = (
  input: string,
): input is SupportedCountryAlpha2Code =>
  SchemaCountryAlpha2Code.safeParse(input).success;

export const supportedCountryToPlaidCountryCode = (
  country: SupportedCountryAlpha2Code,
): CountryCode | undefined => {
  return CountryCode[
    upperFirst(country.toLowerCase()) as keyof typeof CountryCode
  ];
};

export const sqlEnumToCountryCode = (
  country: SqlCountryCodeEnum,
): SupportedCountryAlpha2Code => {
  const result: SupportedCountryAlpha2Code = country;
  return result;
};

export const supportedCountryToSqlEnum = (
  country: SupportedCountryAlpha2Code,
): SqlCountryCodeEnum => {
  return SqlCountryCodeEnum[country];
};

export const SUPPORTED_COUNTRY_ALPHA2_CODES: SupportedCountryAlpha2Code[] =
  Object.values(SqlCountryCodeEnum).map((x) =>
    sqlEnumToCountryCode(SqlCountryCodeEnum[x]),
  );
