export const appColors = {
  primary: '#3e4f52',
  secondary: '#F0EBE3',
  secondaryDarker: '#dfd9cc',
  secondaryLighter: '#fffdfaDD',

  lightGrey: '#e0e0e0',
  white: '#FFFFFF',

  charts: {
    deposits: '#005FAA',
    withdrawals: '#FF6F50',
    balance: '#61CDBB',
    palette: [
      '#ffb400',
      '#82C272',
      '#00A88F',
      '#0087AC',
      '#005FAA',
      '#323B81',
      '#d7658b',
      '#badbdb',
      '#54bebe',
      '#ffb55a',
      '#df979e',
      '#beb9db',
      '#dedad2',
      '#fdcce5',
      '#fd7f6f',
      '#599e94',
      '#d2980d',
      '#6d4b4b',
      '#ffee65',
      '#776bcd',
      '#6cd4c5',
      '#466964',
      '#e4bcad',
      '#b2e061',
      '#333333',
      '#7eb0d5',
      '#8bd3c7',
      '#503f3f',
      '#76c8c8',
      '#5e569b',
      '#e27c7c',
      '#bd7ebe',
      '#3c4e4b',
      '#98d1d1',
      '#c80064',
      '#363445',
      '#a57c1b',
      '#786028',
      '#48446e',
      '#9080ff',
      '#a86464',
    ],
  },
};

export const getColorPalette = (count: number) => {
  if (count <= appColors.charts.palette.length)
    return appColors.charts.palette.slice(0, count);
  else {
    // in the unlikely case that someone needs more colors than the ones we have in the palette,
    // generate the rest randomly
    const result = [...appColors.charts.palette];
    for (let i = 0; i < count - appColors.charts.palette.length; i++) {
      const color = Math.floor(Math.random() * 0xffffff).toString(16);
      result.push('#' + color.padStart(6, '0'));
    }
    return result;
  }
};

export const FONTS = {
  lato: 'Lato',
  latoBold: 'LatoBold',
  inter: 'Inter',
  interBold: 'InterBold',
  montserratBold: 'MontserratBold',
  montserratExtraBold: 'MontserratExtraBold',
};
