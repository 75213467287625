import { wrapMutation } from '@fresh-stack/frontend-commons';
import {
  DateOrStringOrNumber,
  match,
  pipe,
} from '@fresh-stack/fullstack-commons';
import ReplayIcon from '@mui/icons-material/Replay';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Grid,
  IconButton,
  LinearProgress,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React from 'react';
import { useUserContext } from '../../app/hooks/userContext';
import { trpc } from '../../utils';

export const AIDemo = () => {
  const { user: email } = useUserContext();

  const [interactions, setInteractions] = React.useState<{
    readonly values: readonly InteractionPayload[];
  }>({
    values: [],
  });

  const [prompt, setPrompt] = React.useState('');
  const [chainOfThought, setChainOfThought] = React.useState<
    | undefined
    | readonly {
        readonly message: string;
        readonly timestamp: string;
        readonly payload: undefined | object;
      }[]
  >();

  const { mutateAsync, isLoading } = wrapMutation(trpc.ai.askAi.useMutation());

  const onSend = async (optional?: string | undefined) => {
    const question = optional || prompt;
    setPrompt('');
    pipe(
      await mutateAsync(question),
      match(
        (result) => {
          setChainOfThought(result.chainOfThought);
          setInteractions({
            values: [
              {
                question: question,
                date: new Date(),
                value: { kind: 'success', value: result.result },
              },
              ...interactions.values,
            ],
          });
        },
        (err) => {
          console.error('error', err);
          setInteractions({
            values: [
              {
                question: question,
                date: new Date(),
                value: { kind: 'error', code: err.code },
              },
              ...interactions.values,
            ],
          });
          setPrompt(question);
        },
      ),
    );
  };

  return (
    <Box>
      <Grid container item xs={12} md={12} spacing={1}>
        <Grid container item xs={12} md={6}>
          <Stack spacing={2} sx={{ width: '100%' }}>
            <Alert severity="info">
              Ask our AI to generate a response to the prompt you provide.
            </Alert>
            <Stack direction={'row'} spacing={2} alignItems={'center'}>
              <LoadingButton
                sx={{ height: 40, width: 150 }}
                onClick={() => onSend()}
                loading={isLoading}
                disabled={!prompt?.trim()}
                variant="contained"
              >
                Ask AI
              </LoadingButton>
              <TextField
                disabled={isLoading}
                value={prompt}
                label={'Prompt'}
                sx={{ maxWidth: 800, width: 800 }}
                placeholder="How does my cash position compare to 1 year ago?"
                multiline
                rows={2}
                onChange={(value) => setPrompt(value.target.value)}
              ></TextField>
            </Stack>
            <Stack
              pl={5}
              overflow={'auto'}
              maxHeight={'50vh'}
              padding={1}
              spacing={2}
            >
              {interactions.values.map((x, i) => (
                <Interaction
                  onAskAgain={() => onSend(x.question)}
                  highlight={i === 0}
                  date={x.date}
                  question={x.question}
                  value={x.value}
                  email={email}
                  disabled={isLoading}
                />
              ))}
            </Stack>
          </Stack>
        </Grid>
        {chainOfThought?.length && (
          <Stack spacing={1} maxWidth={400} ml={2}>
            <Typography variant="h3">Chain of thought</Typography>
            {chainOfThought.map((thought) => (
              <Stack border={1} padding={1}>
                <Typography variant="caption">{thought.timestamp}</Typography>
                <Typography>{thought.message}</Typography>
                <Typography>{JSON.stringify(thought.payload)}</Typography>
              </Stack>
            ))}
          </Stack>
        )}
      </Grid>
    </Box>
  );
};

interface InteractionPayload {
  readonly question: string;
  readonly date: DateOrStringOrNumber;
  readonly value:
    | { readonly kind: 'loading' }
    | {
        readonly kind: 'error';
        readonly code: string;
      }
    | { readonly kind: 'success'; readonly value: string };
}

const Interaction = ({
  question,
  date,
  value,
  email,
  highlight,
  onAskAgain,
  disabled,
}: InteractionPayload & {
  readonly email: string;
  readonly highlight: boolean;
  readonly disabled: boolean;
  readonly onAskAgain: () => void;
}) => {
  return (
    <Stack
      borderRadius={2}
      border={highlight ? 2 : 1}
      padding={1}
      bgcolor={highlight ? '#bbfce744' : 'ghostwhite'}
      spacing={1}
    >
      <Box>
        {highlight && (
          <Typography variant="caption"> Most recent query</Typography>
        )}
        <Box display={'flex'} alignItems={'center'}>
          <Typography>{new Date(date).toISOString()}</Typography>
          <Tooltip title="Ask again">
            <IconButton
              onClick={onAskAgain}
              disabled={disabled}
              color="primary"
            >
              <ReplayIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <Stack direction="row" spacing={1}>
        <Typography
          fontWeight={600}
          textAlign={'left'}
          sx={{ textDecoration: 'underline' }}
        >
          {email}:
        </Typography>
        <Typography textAlign={'left'}>{question}</Typography>
      </Stack>
      {value.kind === 'error' ? (
        <Alert
          severity="error"
          title={`There was an issue getting an answer (${value.code}). Please try again!`}
        />
      ) : value.kind === 'loading' ? (
        <LinearProgress variant="indeterminate" />
      ) : (
        <Stack direction="row" spacing={1}>
          <Typography
            fontWeight={600}
            textAlign={'left'}
            sx={{ textDecoration: 'underline' }}
          >
            Rafiki:
          </Typography>
          <Typography textAlign={'left'}>{value.value}</Typography>
        </Stack>
      )}
    </Stack>
  );
};
