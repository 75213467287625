import {
  Result,
  UnpackedError,
  buildError,
  flatWrapAsync,
} from '@fresh-stack/fullstack-commons';
import { MutateOptions } from '@tanstack/react-query';
import { TRPCClientErrorLike } from '@trpc/client';
import {
  type UseTRPCMutationResult,
  type UseTRPCQueryResult,
} from '@trpc/react-query/shared';
import { AnyProcedure } from '@trpc/server';

type QueryResult<
  TSuccess extends NonNullable<unknown>,
  TError extends string,
  TProcedure extends AnyProcedure,
> = Omit<
  UseTRPCQueryResult<Result<TSuccess, TError>, TRPCClientErrorLike<TProcedure>>,
  'data' | 'error'
> & {
  readonly result?: Result<TSuccess, TError | 'io-failed'>;
};

// TODO: add tests
/** Wrapper around TRPC queries, resolving io errors as TypedError<'io-error'> */
export const wrapQuery = <
  TSuccess extends NonNullable<unknown>,
  TError extends string,
  TProcedure extends AnyProcedure,
>({
  error,
  data,
  ...rest
}: UseTRPCQueryResult<
  Result<TSuccess, TError>,
  TRPCClientErrorLike<TProcedure>
>): QueryResult<TSuccess, TError, TProcedure> => ({
  ...rest,
  result: error ? buildError('io-failed', error) : data,
});

type MutationResult<
  TSuccess extends NonNullable<unknown>,
  TError extends string,
  TProcedure extends AnyProcedure,
  TVariables,
  TContext,
> = Omit<
  UseTRPCMutationResult<
    Result<TSuccess, TError>,
    TRPCClientErrorLike<TProcedure>,
    TVariables,
    TContext
  >,
  'data' | 'error' | 'mutateAsync'
> & {
  readonly result?: Result<TSuccess, TError | 'io-failed'>;
  readonly mutateAsync: (
    variables: TVariables,
    options?: MutateOptions<
      Result<TSuccess, TError>,
      TRPCClientErrorLike<TProcedure>,
      TVariables,
      TContext
    >,
  ) => Promise<Result<TSuccess, TError | 'io-failed'>>;
};

/** Type-safe wrapper around TRPC mutations. This ensures that `mutateAsync` never rejects
 * (instead returning an error result) and wraps error and data similar to `wrapQuery`.
 */
export const wrapMutation = <
  TSuccess extends NonNullable<unknown>,
  TError extends string,
  TProcedure extends AnyProcedure,
  TVariables,
  TContext,
>({
  error,
  data,
  mutateAsync: mutateAsyncOrig,
  ...rest
}: UseTRPCMutationResult<
  Result<TSuccess, TError>,
  TRPCClientErrorLike<TProcedure>,
  TVariables,
  TContext
>): MutationResult<TSuccess, TError, TProcedure, TVariables, TContext> => ({
  ...rest,
  result: error ? buildError('io-failed', error) : data,
  mutateAsync: async (variables: TVariables) =>
    await flatWrapAsync(() => mutateAsyncOrig(variables), 'io-failed'),
});
export class FrontEndError extends Error {
  constructor(message: string, code: string, cause: UnpackedError) {
    const { name, stack, code: unpackedCode, message: causeMessage } = cause;
    const combinedMessage = `${code}: ${unpackedCode} ${name}: ${message}`;
    super(combinedMessage);

    console.error(
      `${combinedMessage}` +
        `\nCaused by: ${causeMessage}` +
        `\n${stack ?? ''}`,
      `\nJSON: ${JSON.stringify(cause) ?? ''}`,
    );

    this.name = 'FrontEndError';
  }
}
