/* eslint-disable no-restricted-imports */
import {
  Either,
  flatMap as EitherFlatMap,
  fromNullable as EitherFromNullable,
  map as EitherMap,
  match as EitherMatch,
  flattenW as EitherFlattenW,
  Left,
  Right,
  isLeft,
  isRight,
  left,
  mapLeft,
  right,
} from 'fp-ts/Either';
import { Option, fromNullable as OptionFromNullable } from 'fp-ts/Option';
import { map as TaskEitherMap, tryCatch } from 'fp-ts/TaskEither';
import { flow, pipe } from 'fp-ts/function';
import { UnpackedError, unpackError } from './unpack-error';

export type TypedError<T extends string> = {
  readonly code: T;
  readonly unpackedError: UnpackedError;
};

export type SuccessResult<A extends NonNullable<unknown>> = Right<A>;
export type ErrorResult<T extends string> = Left<TypedError<T>>;

export type Result<
  TSuccess extends NonNullable<unknown>,
  TError extends string,
> = Either<TypedError<TError>, TSuccess>;

export const isActionResult = <
  TSuccess extends NonNullable<unknown>,
  TError extends string,
>(
  x: unknown,
): x is Result<TSuccess, TError> => {
  return (
    x !== null &&
    x !== undefined &&
    typeof x === 'object' &&
    '_tag' in x &&
    ('left' in x || 'right' in x)
  );
};

export type AsyncResult<
  TSuccess extends NonNullable<unknown>,
  TError extends string,
> = Promise<Result<TSuccess, TError>>;

/** Get the payload from the success branch of an (Async)Result */
export type ExtractPayload<T> =
  T extends Result<infer P, string>
    ? P
    : T extends AsyncResult<infer P, string>
      ? P
      : never;

/** Get the error string(s) from the error branch of an (Async)(Optional)ActionResult */
export type ExtractError<T> =
  T extends Result<NonNullable<unknown>, infer P>
    ? P
    : T extends AsyncResult<NonNullable<unknown>, infer P>
      ? P
      : never;

export const buildSuccess: <
  TSuccess extends NonNullable<unknown> = never,
  TError extends string = never,
>(
  x: TSuccess,
) => Result<TSuccess, TError> = right;

export const buildTypedError = <TError extends string = never>(
  code: TError,
  err?: unknown,
): TypedError<TError> => ({
  code,
  unpackedError: unpackError(err),
});

export const buildError: <
  TSuccess extends NonNullable<unknown> = never,
  TError extends string = never,
>(
  code: TError,
  err?: unknown,
) => Result<TSuccess, TError> = flow(buildTypedError, left);

export const isSuccess: <
  TSuccess extends NonNullable<unknown> = never,
  TError extends string = never,
>(
  result: Result<TSuccess, TError>,
) => result is SuccessResult<TSuccess> = isRight;

export const isError: <
  TSuccess extends NonNullable<unknown> = never,
  TError extends string = never,
>(
  result: Result<TSuccess, TError>,
) => result is ErrorResult<TError> = isLeft;

export const errorIsCode = <TError extends string, NarrowError extends TError>(
  x: TypedError<TError>,
  code: NarrowError,
): x is TypedError<NarrowError> => {
  return x.code === code;
};

export const isErrorCode = <
  TSuccess extends NonNullable<unknown>,
  TError extends string,
  NarrowError extends TError,
>(
  x: Result<TSuccess, TError>,
  code: NarrowError,
): x is ErrorResult<NarrowError> => {
  return isError(x) && errorIsCode(x.left, code);
};

export const getPayload = <S extends NonNullable<unknown>>(
  x: SuccessResult<S>,
): S => {
  return x.right;
};

export const getError = <T extends string>(
  x: ErrorResult<T>,
): TypedError<T> => {
  return x.left;
};

export const flatten: {
  <TSuccess extends NonNullable<unknown>, E1 extends string, E2 extends string>(
    x: Result<Result<TSuccess, E1>, E2>,
  ): Result<TSuccess, E1 | E2>;
} = EitherFlattenW;

export const flatMapSuccess: {
  <
    S1 extends NonNullable<unknown>,
    S2 extends NonNullable<unknown>,
    E2 extends string,
  >(
    f: (a: S1) => Result<S2, E2>,
  ): <E1 extends string>(ma: Result<S1, E1>) => Result<S2, E1 | E2>;
  <
    S1 extends NonNullable<unknown>,
    E1 extends string,
    S2 extends NonNullable<unknown>,
    E2 extends string,
  >(
    f: (a: S1) => Result<S2, E2>,
  ): (ma: Result<S1, E1>) => Result<S2, E1 | E2>;
} = EitherFlatMap;

export const asyncFlatMapSuccess: {
  <
    S1 extends NonNullable<unknown>,
    S2 extends NonNullable<unknown>,
    E extends string,
  >(
    f: (a: S1) => AsyncResult<S2, E>,
  ): (ma: Result<S1, E>) => AsyncResult<S2, E>;
  <
    S1 extends NonNullable<unknown>,
    E1 extends string,
    S2 extends NonNullable<unknown>,
    E2 extends string,
  >(
    f: (a: S1) => AsyncResult<S2, E2>,
  ): (ma: Result<S1, E1>) => AsyncResult<S2, E1 | E2>;
  <
    S1 extends NonNullable<unknown>,
    S2 extends NonNullable<unknown>,
    E2 extends string,
  >(
    f: (a: S1) => AsyncResult<S2, E2>,
  ): <E1 extends string>(ma: Result<S1, E1>) => AsyncResult<S2, E1 | E2>;
} =
  <
    S1 extends NonNullable<unknown>,
    E1 extends string,
    S2 extends NonNullable<unknown>,
    E2 extends string,
  >(
    f: (a: S1) => AsyncResult<S2, E2>,
  ) =>
  async (ma: Result<S1, E1>): AsyncResult<S2, E1 | E2> =>
    isSuccess(ma) ? f(ma.right) : Promise.resolve(ma);

export const mapSuccess: {
  <
    S1 extends NonNullable<unknown>,
    S2 extends NonNullable<unknown>,
    E extends string,
  >(
    f: (a: S1) => S2,
  ): (fa: Result<S1, E>) => Result<S2, E>;
  <S1 extends NonNullable<unknown>, S2 extends NonNullable<unknown>>(
    f: (a: S1) => S2,
  ): <E extends string>(fa: Result<S1, E>) => Result<S2, E>;
} = EitherMap;

export const resultFromNullable: <E extends string>(
  code: E,
  err?: unknown,
) => <A>(a: A | undefined | null) => Result<NonNullable<A>, E> = flow(
  buildTypedError,
  EitherFromNullable,
);

export const inspectSuccess: <
  TSuccess extends NonNullable<unknown>,
  TError extends string,
>(
  f: (x: TSuccess) => void,
) => (x: Result<TSuccess, TError>) => Result<TSuccess, TError> =
  <TSuccess extends NonNullable<unknown>>(f: (x: TSuccess) => void) =>
  <TError extends string>(
    x: Result<TSuccess, TError>,
  ): Result<TSuccess, TError> => {
    if (isSuccess(x)) {
      f(x.right);
    }
    return x;
  };

export const inspectError: {
  <TError extends string>(
    f: (x: TypedError<TError>) => void,
  ): <TSuccess extends NonNullable<unknown>>(
    x: Result<TSuccess, TError>,
  ) => Result<TSuccess, TError>;
  (
    f: <TError extends string>(x: TypedError<TError>) => void,
  ): <TSuccess extends NonNullable<unknown>, TError extends string>(
    x: Result<TSuccess, TError>,
  ) => Result<TSuccess, TError>;
} =
  <TError extends string>(f: (x: TypedError<TError>) => void) =>
  <TSuccess extends NonNullable<unknown>>(
    x: Result<TSuccess, TError>,
  ): Result<TSuccess, TError> => {
    if (isLeft(x)) {
      f(x.left);
    }
    return x;
  };

export const asyncInspectError: <TError extends string>(
  f: (x: TypedError<TError>) => void,
) => <TSuccess extends NonNullable<unknown>>(
  x: Promise<Result<TSuccess, TError>>,
) => Promise<Result<TSuccess, TError>> =
  <TError extends string>(f: (x: TypedError<TError>) => void) =>
  async <TSuccess extends NonNullable<unknown>>(
    x: Promise<Result<TSuccess, TError>>,
  ): Promise<Result<TSuccess, TError>> => {
    const resolved = await x;
    if (isLeft(resolved)) {
      f(resolved.left);
    }
    return resolved;
  };

export const mapError: <E extends string, G extends string>(
  f: (e: TypedError<E>) => TypedError<G>,
) => <A extends NonNullable<unknown>>(fa: Result<A, E>) => Result<A, G> =
  mapLeft;

export const match: <E extends string, S extends NonNullable<unknown>, T>(
  mapSuccess: (a: S) => T,
  mapError: (e: TypedError<E>) => T,
) => (ma: Result<S, E>) => T = (s, e) => EitherMatch(e, s);

export const ignoreError: {
  <S extends NonNullable<unknown>, E extends string, T extends S = S>(
    defaultValue: T,
  ): (ma: Result<S, E>) => S;
  <S extends NonNullable<unknown>>(
    defaultValue: S,
  ): <E extends string>(ma: Result<S, E>) => S;
} = <S extends NonNullable<unknown>>(defaultValue: S) =>
  match(
    (x) => x,
    () => defaultValue,
  );

export const throwError: <E extends string>(
  f: (e: TypedError<E>) => Error,
) => <S extends NonNullable<unknown>>(ma: Result<S, E>) => S = (f) =>
  match(
    (x) => x,
    (e) => {
      throw f(e);
    },
  );

export const wrapAsync: {
  <TSuccess extends NonNullable<unknown>>(
    f: () => Promise<TSuccess>,
  ): AsyncResult<TSuccess, 'unknown'>;
  <TSuccess extends NonNullable<unknown>, TError extends string>(
    f: () => Promise<TSuccess>,
    defaultCode: TError,
  ): AsyncResult<TSuccess, TError>;
} = async <
  TSuccess extends NonNullable<unknown>,
  TError extends string = 'unknown',
>(
  f: () => Promise<TSuccess>,
  defaultCode?: TError,
): AsyncResult<TSuccess, TError | 'unknown'> => {
  const code = defaultCode ?? 'unknown';
  return tryCatch<TypedError<TError | 'unknown'>, TSuccess>(f, (err) => ({
    code,
    unpackedError: unpackError(err),
  }))();
};

/** Wrap an async function which can either return an AsyncResult or throw.
 *
 * This should be used, for example, to wrap a function which takes a callback
 * if we provide a AsyncResult callback.
 */
export const flatWrapAsync: {
  <TSuccess extends NonNullable<unknown>, TError extends string>(
    f: () => AsyncResult<TSuccess, TError>,
  ): AsyncResult<TSuccess, TError | 'unknown'>;
  <
    TSuccess extends NonNullable<unknown>,
    TError1 extends string,
    TError2 extends string,
  >(
    f: () => AsyncResult<TSuccess, TError1>,
    defaultCode: TError2,
  ): AsyncResult<TSuccess, TError1 | TError2>;
} = async <
  TSuccess extends NonNullable<unknown>,
  TError1 extends string,
  TError2 extends string = 'unknown',
>(
  f: () => AsyncResult<TSuccess, TError1>,
  defaultCode?: TError2,
): AsyncResult<TSuccess, TError1 | TError2 | 'unknown'> => {
  return pipe(await wrapAsync(f, defaultCode ?? 'unknown'), flatten);
};

export const wrapAsyncOptional = async <TSuccess extends NonNullable<unknown>>(
  f: () => Promise<TSuccess | null | undefined>,
): AsyncResult<Option<NonNullable<TSuccess>>, 'unknown'> => {
  return pipe(
    tryCatch<TypedError<'unknown'>, TSuccess | null | undefined>(f, (err) => ({
      code: 'unknown',
      unpackedError: unpackError(err),
    })),
    TaskEitherMap(OptionFromNullable),
  )();
};

export const flattenResultArray = <
  TSuccess extends NonNullable<unknown>,
  TError extends string,
>(
  x: Result<TSuccess, TError>[],
): Result<TSuccess[], TError> => {
  const errors = x.filter(isError).map((e) => e.left);
  if (errors.length > 0) {
    const firstError = errors[0];
    return buildError(
      firstError.code,
      `Error(s) found in array: ${JSON.stringify(errors)}`,
    );
  } else {
    return right(
      x.map((r) => {
        // TODO: make this nicer
        if (isError(r)) {
          throw new Error('AssertionError: This should never happen');
        }
        return r.right;
      }),
    );
  }
};
