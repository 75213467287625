import { wrapMutation, wrapQuery } from '@fresh-stack/frontend-commons';
import { isError, isSuccess } from '@fresh-stack/fullstack-commons';
import { zodResolver } from '@hookform/resolvers/zod';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LoadingButton } from '@mui/lab';
import {
  Accordion,
  Alert,
  Box,
  Grid,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import z from 'zod';
import { trpc } from '../../utils';

const SchemaDomain = z.object({
  domain: z.string().min(2, { message: 'Invalid domain.' }),
  status: z.union([
    z.literal('allowed'),
    z.literal('pending'),
    z.literal('denied'),
  ]),
});

type DomainUpdate = z.infer<typeof SchemaDomain>;

const columns: readonly GridColDef[] = [
  { field: 'domain', headerName: 'Domain', width: 200 },
  { field: 'updatedAt', headerName: 'Updated at', width: 200 },
  { field: 'status', headerName: 'Allow status', width: 200 },
];

export const ApprovedSignupDomains = () => {
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>();
  const [successMessage, setSuccessMessage] = React.useState<
    string | undefined
  >();
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    control,
    reset,
  } = useForm<DomainUpdate>({
    resolver: zodResolver(SchemaDomain),
    defaultValues: {
      status: 'allowed',
    },
  });

  const {
    result: domains,
    isFetching,
    refetch,
  } = wrapQuery(trpc.users.getSignupDomains.useQuery());
  const { mutateAsync: updateDomain, isLoading } = wrapMutation(
    trpc.users.updateSignupDomain.useMutation(),
  );

  const onAddDomain = async (input: DomainUpdate) => {
    const result = await updateDomain(input);
    if (isError(result)) {
      setErrorMessage('Had an issue saving, please try again.');
    } else {
      setSuccessMessage(
        'Updated, you should see the updated value in the list now.',
      );
      reset();
    }
    refetch();
  };

  return (
    <Stack spacing={2}>
      {errorMessage && (
        <Alert
          variant="filled"
          severity="error"
          onClose={() => setErrorMessage(undefined)}
        >
          {errorMessage}
        </Alert>
      )}
      {successMessage && (
        <Alert
          variant="filled"
          severity="success"
          onClose={() => setSuccessMessage(undefined)}
        >
          {successMessage}
        </Alert>
      )}
      <form onSubmit={handleSubmit(onAddDomain)}>
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography variant="h5" fontWeight={600}>
              Set domain approval
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container item justifyContent={'space-around'}>
              <TextField
                id="domain"
                label="Domain"
                placeholder="getribon.com"
                {...register('domain')}
                variant="filled"
                color="primary"
                size="small"
                error={!!errors.domain}
                helperText={
                  errors.domain ? <Box>{errors.domain.message}</Box> : null
                }
              />
              <Controller
                name="status"
                control={control}
                render={({ field }) => {
                  return (
                    <ToggleButtonGroup
                      size="small"
                      {...field}
                      color="primary"
                      onChange={(
                        _event: React.MouseEvent<HTMLElement>,
                        value: 'allowed' | 'pending' | 'denied' | undefined,
                      ) => {
                        if (value) setValue(field.name, value);
                      }}
                      exclusive
                      aria-label="text alignment"
                    >
                      <ToggleButton value="allowed">Allowed</ToggleButton>
                      <ToggleButton value="pending">Pending</ToggleButton>
                      <ToggleButton value="denied">Denied</ToggleButton>
                    </ToggleButtonGroup>
                  );
                }}
              ></Controller>
              <LoadingButton
                type="submit"
                variant="contained"
                color="primary"
                sx={{ width: 'fit-content' }}
                loading={isFetching || isLoading}
              >
                Update
              </LoadingButton>
            </Grid>
          </AccordionDetails>
          <Box pl={2} pb={2}></Box>
        </Accordion>
      </form>
      {domains && isError(domains) && (
        <Alert severity="error">
          There was an issue loading the current domain list.
        </Alert>
      )}
      <Box sx={{ width: '100%', maxHeight: '55vh' }}>
        <DataGridPro
          sx={{
            boxShadow: 4,
            padding: 1,
          }}
          loading={isFetching}
          rows={
            domains && isSuccess(domains)
              ? domains.right.map((x) => ({
                  id: x.domain,
                  updatedAt: x.updatedAt && new Date(x.updatedAt),
                  ...x,
                }))
              : []
          }
          columns={columns}
          pagination
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          pageSizeOptions={[5, 10, 25]}
        />
      </Box>
    </Stack>
  );
};
