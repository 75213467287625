import {
  TRPC_ERROR_CODES_BY_KEY,
  TRPC_ERROR_CODE_KEY,
} from './trpc-error-codes';

export const UNKNOWN_ERROR = {
  name: 'UnknownError',
  message: 'An unknown error has occurred.',
  stack: '',
};

export interface UnpackedError {
  readonly name: string;
  readonly message: string;
  readonly stack: string;
  readonly code: TRPC_ERROR_CODE_KEY;
}

const isTrpcErrorCode = (str: string): str is TRPC_ERROR_CODE_KEY =>
  str in TRPC_ERROR_CODES_BY_KEY;

const objectHasKey = <T extends string>(
  obj: unknown,
  key: T,
): obj is Record<T, string> => {
  return (
    typeof obj === 'object' &&
    obj !== null &&
    key in obj &&
    typeof (obj as Record<T, unknown>)[key] === 'string'
  );
};

export const unpackError = (maybeError: unknown): UnpackedError => {
  const name = objectHasKey(maybeError, 'name')
    ? maybeError.name
    : UNKNOWN_ERROR.name;

  const message = objectHasKey(maybeError, 'message')
    ? maybeError.message
    : UNKNOWN_ERROR.message;

  const stack = objectHasKey(maybeError, 'stack')
    ? maybeError.stack
    : UNKNOWN_ERROR.stack;

  const code =
    objectHasKey(maybeError, 'code') && isTrpcErrorCode(maybeError.code)
      ? maybeError.code
      : 'INTERNAL_SERVER_ERROR';

  return {
    name,
    message,
    stack,
    code,
  };
};
