import { wrapQuery } from '@fresh-stack/frontend-commons';
import { isError, isSuccess } from '@fresh-stack/fullstack-commons';
import { Alert, Box, Button, Drawer, Stack, Typography } from '@mui/material';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import React from 'react';
import { trpc } from '../../utils';

const accountColumns: readonly GridColDef[] = [
  { field: 'id', headerName: 'Id', width: 200 },
  { field: 'improvisedAccountId', headerName: 'Unique Account Id', width: 250 },
  { field: 'accountName', headerName: 'Account Name', width: 200 },
  { field: 'mask', headerName: 'Mask' },
  { field: 'type', headerName: 'Type' },
  { field: 'subtype', headerName: 'Subtype' },
  { field: 'createdAt', headerName: 'Created At' },
  { field: 'companyId', headerName: 'Company Id', width: 200 },
  { field: 'companyName', headerName: 'Company Name', width: 300 },
];

export const AccountListPage = () => {
  const [debug, setDebug] = React.useState<object | undefined>();

  const { result: maybeAccounts, isLoading } = wrapQuery(
    trpc.actions.getAccountList.useQuery(undefined, {
      refetchOnWindowFocus: false,
    }),
  );

  return (
    <Stack width={'100%'} spacing={2}>
      <Typography variant="subtitle1">Account list</Typography>

      {maybeAccounts && isError(maybeAccounts) && (
        <Alert severity="error">
          There was a problem loading accounts, refresh the page to retry!
        </Alert>
      )}
      <Box sx={{ width: '100%', maxHeight: '55vh' }}>
        <DataGridPro
          sx={{
            boxShadow: 4,
            padding: 1,
            maxWidth: '75%',
          }}
          autoHeight
          loading={isLoading}
          rows={
            maybeAccounts && isSuccess(maybeAccounts) ? maybeAccounts.right : []
          }
          columns={accountColumns}
          pagination
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          pageSizeOptions={[10, 25, 50]}
        />
      </Box>

      <Drawer open={!!debug} anchor={'right'} variant="permanent">
        {debug && (
          <Box width={'100%'} padding={2}>
            <Button
              onClick={() => setDebug(undefined)}
              variant="contained"
              color="error"
            >
              Close
            </Button>
            <pre>{JSON.stringify(debug, null, 2)}</pre>
          </Box>
        )}
      </Drawer>
    </Stack>
  );
};
