import { MIN_DATE } from '@fresh-stack/fullstack-commons';
import { List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import {
  DatePicker,
  PickersActionBarProps,
  pickersLayoutClasses,
} from '@mui/x-date-pickers-pro';
import { endOfMonth, startOfMonth, subMonths } from 'date-fns';
import { useCallback, useMemo } from 'react';

const ACTION_DATES = [
  { text: 'This month', value: startOfMonth(new Date()) },
  { text: 'Last 3 months', value: startOfMonth(subMonths(new Date(), 2)) },
  { text: 'Last 6 months', value: startOfMonth(subMonths(new Date(), 5)) },
  { text: 'Last year', value: startOfMonth(subMonths(new Date(), 11)) },
  { text: 'Last 2 years', value: startOfMonth(subMonths(new Date(), 23)) },
] as const;

export const MonthPicker = ({
  value,
  onValueChanged,
  label,
  disabled,
  hidePresets,
}: {
  readonly value: Date | undefined;
  readonly onValueChanged: (newValue: Date) => void;
  readonly label: string;
  readonly disabled?: boolean;
  readonly hidePresets?: boolean;
}) => {
  const actionButtons = useMemo(
    () =>
      ACTION_DATES.map(({ text, value }) => ({
        text,
        onClick: () => onValueChanged(value),
      })),
    [onValueChanged],
  );

  const CustomActionBar = useCallback(
    (props: PickersActionBarProps) => (
      <List className={props.className}>
        {actionButtons.map((action) => (
          <ListItem key={action.text} disablePadding>
            <ListItemButton onClick={action.onClick}>
              <ListItemText primary={action.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    ),
    [actionButtons],
  );

  return (
    <DatePicker
      disabled={disabled}
      label={label}
      sx={{ width: 200 }}
      value={value ?? null}
      views={['year', 'month']}
      minDate={MIN_DATE}
      maxDate={endOfMonth(new Date())}
      onChange={(newValue) => {
        if (newValue && newValue !== value)
          onValueChanged(newValue ?? undefined);
      }}
      slots={{
        actionBar: hidePresets ? undefined : CustomActionBar,
      }}
      slotProps={{
        textField: {
          size: 'small',
        },
        layout: {
          sx: {
            [`.${pickersLayoutClasses.actionBar}`]: {
              gridColumn: 1,
              gridRow: 2,
            },
          },
        },
      }}
    />
  );
};
