import { Fade } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { ReactElement, Ref, forwardRef } from 'react';

export const DialogFadeTransition = forwardRef(function Transition(
  props: TransitionProps & {
    readonly children: ReactElement;
  },
  ref: Ref<unknown>,
) {
  return <Fade in ref={ref} {...props} />;
});
