import { wrapMutation } from '@fresh-stack/frontend-commons';
import {
  DateOrStringOrNumber,
  Result,
  isSuccess,
} from '@fresh-stack/fullstack-commons';
import { LoadingButton } from '@mui/lab';
import { Alert, Stack } from '@mui/material';
import React from 'react';
import { trpc } from '../../utils';

export const ErrorAlertDemo = () => {
  const { mutateAsync, isLoading } = wrapMutation(
    trpc.error.generateError.useMutation(),
  );
  const [status, setStatus] = React.useState<
    Result<{ readonly date: DateOrStringOrNumber }, string> | undefined
  >();

  const onSend = async () => {
    setStatus(undefined);
    const result = await mutateAsync();
    setStatus(result);
  };

  return (
    <Stack spacing={2}>
      <Alert severity="info">
        This creates an error log in the application that triggers an alert for
        engineering.
      </Alert>
      <LoadingButton
        sx={{ height: 40 }}
        onClick={onSend}
        loading={isLoading}
        variant="contained"
      >
        Trigger error
      </LoadingButton>

      {status && isSuccess(status) && (
        <Alert severity="success">
          Error triggered: {status.right.date.toString()}
        </Alert>
      )}
    </Stack>
  );
};
