import { Loader, wrapQuery } from '@fresh-stack/frontend-commons';
import { compact, isError } from '@fresh-stack/fullstack-commons';
import {
  Alert,
  Box,
  Button,
  Drawer,
  Grid,
  Stack,
  Table,
  Typography,
} from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { DataGridPro } from '@mui/x-data-grid-pro';
import React from 'react';
import { useParams } from 'react-router-dom';
import { trpc } from '../../utils';
import { InviteCompanyUser } from './InviteCompanyUser';

const userColumns = [
  { field: 'id', headerName: 'Id', width: 350 },
  { field: 'firstName', headerName: 'First name' },
  { field: 'lastName', headerName: 'Last name' },
  { field: 'email', headerName: 'Email' },
  { field: 'cellphoneNumber', headerName: 'Cellphone' },
  { field: 'roleId', headerName: 'Role' },
];

export const ManageCompany = () => {
  const { id } = useParams();
  const {
    result: maybeCompany,
    isFetching,
    refetch,
  } = wrapQuery(
    trpc.users.getCompanyWithUsers.useQuery(id ?? '', {
      refetchOnWindowFocus: false,
      enabled: !!id,
    }),
  );

  const [debug, setDebug] = React.useState<object | undefined>();

  const company =
    !maybeCompany || isError(maybeCompany) ? undefined : maybeCompany.right;
  const users = compact(
    company?.companyUserRoles?.map((x) =>
      x.user ? { user: x.user, roleId: x.roleId } : undefined,
    ),
  ).map(({ user: x, roleId }) => ({
    id: x.id,
    firstName: x.firstName,
    lastName: x.lastName,
    email: x.email,
    cellphoneNumber: x.cellphoneNumber,
    roleId: roleId,
  }));

  return !id ? (
    <Alert severity="error">Invalid company id.</Alert>
  ) : (
    <Stack spacing={2}>
      {isFetching ? (
        <Loader />
      ) : !company ? (
        <Alert severity="error" variant="filled">
          There was an issue loading company: {id}
        </Alert>
      ) : (
        <Stack spacing={2}>
          <Box>
            <Typography variant="h6">Company details</Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="left">Id</TableCell>
                  <TableCell align="left">Name</TableCell>
                  <TableCell align="left">Website</TableCell>
                  <TableCell align="left">Created at</TableCell>
                  <TableCell align="left">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableRow hover>
                <TableCell align={'left'}>{company.id}</TableCell>
                <TableCell align={'left'}>{company.legalName}</TableCell>
                <TableCell align={'left'}>{company.homepageUrl}</TableCell>
                <TableCell align={'left'}>{company.createdAt}</TableCell>
                <TableCell align={'left'}>-</TableCell>
              </TableRow>
            </Table>
          </Box>

          <Grid container item xs={12}>
            <Box sx={{ width: '100%', maxHeight: '50vh', spacing: 2 }}>
              <Typography variant="h6">Company users</Typography>
              <InviteCompanyUser
                onResult={(result) => {
                  setDebug(result);
                  refetch();
                }}
                companyId={id}
              ></InviteCompanyUser>
              <DataGridPro
                sx={{
                  boxShadow: 4,
                  padding: 1,
                  mt: 2,
                  width: 'fit-content',
                }}
                autoHeight
                loading={isFetching}
                rows={users}
                columns={userColumns}
                pagination
                initialState={{
                  pagination: { paginationModel: { pageSize: 10 } },
                }}
                pageSizeOptions={[5, 10, 25]}
              />
            </Box>
            <Drawer open={!!debug} anchor={'right'} variant="permanent">
              {debug && (
                <Box width={'100%'} padding={2}>
                  <Button
                    onClick={() => setDebug(undefined)}
                    variant="contained"
                    color="error"
                  >
                    Close
                  </Button>
                  <pre>{JSON.stringify(debug, null, 2)}</pre>
                </Box>
              )}
            </Drawer>
          </Grid>
        </Stack>
      )}
    </Stack>
  );
};
