import { Box, Drawer, Grid, List } from '@mui/material';
import { getToolingMenu } from '../app/routes';
import Logo from '../assets/images/logo.svg?react';
import { MenuLeafItem, NavBarAvatar, TopLevelMenuItem } from './navbar';

export const DRAWER_WIDTH = 300;

export const ToolingSideMenu = () => {
  return (
    <Drawer
      sx={{
        width: DRAWER_WIDTH,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: DRAWER_WIDTH,
          boxSizing: 'border-box',
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Grid
        container
        item
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Logo color={'secondary'} width={50} />
        <Box>
          <NavBarAvatar />
        </Box>
      </Grid>
      <List
        sx={{ width: '100%', bgcolor: 'background.paper' }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        {getToolingMenu().map((item) =>
          item.kind === 'container' ? (
            <TopLevelMenuItem
              key={'mtli' + item.name + item.path}
              item={item}
            />
          ) : (
            <MenuLeafItem key={'mli' + item.path} item={item} />
          ),
        )}
      </List>
    </Drawer>
  );
  // }
};
