import { NavBarMenuItem } from '@fresh-stack/frontend-commons';
import { Box } from '@mui/material';
import { EmailDemo, FirestoreDemo } from '../../pages';
import { AppCompanies, AppUsers } from '../../pages/UserManagement';
import { ApprovedSignupDomains } from '../../pages/UserManagement/ApprovedSignupDomains';
import { ManageCompany } from '../../pages/UserManagement/ManageCompany';
import { ToolingUsers } from '../../pages/UserManagement/ToolingUsers';
import { AccountListPage } from '../../pages/data/AccountListPage';
import { AIDemo } from '../../pages/demos/AIDemo';
import { ErrorAlertDemo } from '../../pages/demos/ErrorAlertDemo';
import { MossTransactionFilePage } from '../../pages/generate-data/MossTransactionFilePage';
import { ROUTES } from './routes';
import { PlaidTransactionsPage } from '../../pages/data/PlaidTransactionsPage';
import { ReleaseNotificationsPage } from '../../pages/UserManagement/ReleaseNotificationsPage';

const NAVIGATION_MENU: ReadonlyArray<NavBarMenuItem> = [
  {
    kind: 'leaf',
    name: 'Dashboard',
    path: ROUTES.Home,
    element: () => <FirestoreDemo />,
  },
  {
    kind: 'container',
    name: 'Users and companies',
    children: [
      {
        kind: 'leaf',
        name: 'App companies',
        path: ROUTES.UserManagement.AppCompanies,
        element: () => <AppCompanies />,
      },
      {
        kind: 'leaf',
        name: 'Signup approved domains',
        path: ROUTES.UserManagement.ApprovedDomains,
        element: () => <ApprovedSignupDomains />,
      },
      {
        kind: 'leaf',
        name: 'Bank account list',
        path: ROUTES.BankAccounts,
        element: () => <AccountListPage />,
      },
      {
        kind: 'leaf',
        name: 'Plaid transactions',
        path: ROUTES.PlaidTransactions,
        element: () => <PlaidTransactionsPage />,
      },
      {
        kind: 'leaf',
        name: 'App users',
        path: ROUTES.UserManagement.AppUsers,
        element: () => <AppUsers />,
      },
      {
        kind: 'leaf',
        name: 'Release notifications',
        path: ROUTES.UserManagement.Notifications,
        element: () => <ReleaseNotificationsPage />,
      },
    ],
  },
  {
    kind: 'container',
    name: 'Generate data',
    children: [
      {
        kind: 'leaf',
        name: 'Moss transaction file',
        path: ROUTES.GenerateData.MossTransactions,
        element: () => <MossTransactionFilePage />,
      },
    ],
  },
  {
    kind: 'leaf',
    name: 'Tooling users',
    path: ROUTES.ToolingManagement.Users,
    element: () => <ToolingUsers />,
  },
  {
    kind: 'container',
    name: 'Demos',
    children: [
      {
        kind: 'leaf',
        name: 'Firestore Demo',
        path: ROUTES.Demo.Firestore,
        element: () => <FirestoreDemo />,
      },
      {
        kind: 'leaf',
        name: 'Email Demo',
        element: () => <EmailDemo />,
        path: ROUTES.Demo.Email,
      },
      {
        kind: 'leaf',
        name: 'Errors Demo',
        element: () => <ErrorAlertDemo />,
        path: ROUTES.Demo.ErrorAlerts,
      },
      {
        kind: 'leaf',
        name: 'AI Demo',
        element: () => <AIDemo />,
        path: ROUTES.Demo.AI,
      },
    ],
  },
  {
    kind: 'leaf',
    name: 'Storybook',
    path: ROUTES.Storybook,
    element: () => (
      <iframe
        src={ROUTES.Storybook}
        style={{ width: '100%', height: '100vh' }}
        title="Storybook"
      />
    ),
    handling: 'new-tab',
  },
];

export const getToolingMenu = () => {
  return NAVIGATION_MENU;
};

export const getPathElements = (): {
  readonly path: string;
  readonly element: JSX.Element;
}[] => {
  const result: {
    readonly path: string;
    readonly element: JSX.Element;
  }[] = [];

  result.push({
    path: ROUTES.UserManagement.Company,
    element: <ManageCompany />,
  });

  const parse = (input: readonly NavBarMenuItem[]) => {
    for (const item of input) {
      if (item.kind === 'leaf')
        result.push({
          path: item.path,
          element: item.element?.() ?? <Box />,
        });
      else {
        parse(item.children);
      }
    }
  };

  parse(NAVIGATION_MENU);

  return result;
};
