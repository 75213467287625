import { wrapQuery } from '@fresh-stack/frontend-commons';
import { isError } from '@fresh-stack/fullstack-commons';
import RefreshIcon from '@mui/icons-material/Refresh';
import { LoadingButton } from '@mui/lab';
import { Alert, Box, CircularProgress, Divider } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { trpc } from '../../utils';
import { InviteToolingUser } from './InviteToolingUser';

export const ToolingUsers = () => {
  const {
    result: users,
    isFetching,
    isRefetching,
    refetch,
  } = wrapQuery(
    trpc.auth0.getToolingUsers.useQuery(undefined, {
      refetchOnWindowFocus: false,
    }),
  );

  const refresh = () => {
    refetch();
  };
  return (
    <Box width="100%">
      <InviteToolingUser />

      <Divider sx={{ mt: 2, mb: 2 }} />
      <LoadingButton onClick={refresh} loading={isFetching || isRefetching}>
        <RefreshIcon />
      </LoadingButton>
      <TableContainer component={Paper} sx={{ minWidth: 650, maxWidth: 700 }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!users ? (
              <CircularProgress />
            ) : isError(users) ? (
              <Alert severity="error">
                There was an issue loading the tooling admins.
              </Alert>
            ) : (
              users.right.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.id}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.email}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
