import { List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import {
  DatePicker,
  PickersActionBarProps,
  pickersLayoutClasses,
} from '@mui/x-date-pickers-pro';
import { subMonths, subYears } from 'date-fns';
import { useCallback, useMemo } from 'react';

const ACTION_DATES = [
  { text: 'Today', value: new Date() },
  { text: '1 month ago', value: subMonths(new Date(), 1) },
  { text: '3 months ago', value: subMonths(new Date(), 3) },
  { text: '1 year ago', value: subYears(new Date(), 1) },
] as const;

export const FilterDatePicker = ({
  value,
  setValue,
  label,
  disabled = false,
}: {
  readonly value: Date | undefined;
  readonly setValue: (newValue: Date | undefined) => void;
  readonly label: string;
  readonly disabled?: boolean;
}) => {
  const actionButtons = useMemo(
    () =>
      ACTION_DATES.map(({ text, value }) => ({
        text,
        onClick: () => setValue(value),
      })),
    [setValue],
  );

  const CustomActionBar = useCallback(
    (props: PickersActionBarProps) => (
      <List className={props.className}>
        {actionButtons.map((action) => (
          <ListItem key={action.text} disablePadding>
            <ListItemButton onClick={action.onClick}>
              <ListItemText primary={action.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    ),
    [actionButtons],
  );

  return (
    <DatePicker
      disabled={disabled}
      label={label}
      sx={{ width: 185 }}
      value={value ?? null}
      onChange={(newValue) => setValue(newValue ?? undefined)}
      slots={{
        actionBar: CustomActionBar,
      }}
      slotProps={{
        field: {
          clearable: true,
          onClear: () => setValue(undefined),
        },
        textField: {
          size: 'small',
        },
        layout: {
          sx: {
            [`.${pickersLayoutClasses.actionBar}`]: {
              gridColumn: 1,
              gridRow: 2,
            },
          },
        },
      }}
    />
  );
};
