import { useAuth0 } from '@auth0/auth0-react';
import { RouterProvider } from 'react-router-dom';

import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { buildAppRouter } from './app/routes/router';

import { ErrorPage } from './components/ErrorPage';
import { trpc } from './utils';

import './assets/App.css';

import { Loader } from '@fresh-stack/frontend-commons';
import { IconButton, ThemeProvider } from '@mui/material';
import { LicenseInfo, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { enGB } from 'date-fns/locale';
import { SnackbarProvider, closeSnackbar } from 'notistack';
import { UserContext } from './app/hooks/userContext';
import { getToolingMenu } from './app/routes';
import { TrpcProvider } from './components/TrpcProvider';
import { THEME_GLOBAL } from './theme';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

const UserRouter = () => {
  // this is here to force trpc to initialise the client immediately, which also triggers the token aquire from auth0
  const { data } = trpc.users.checkUser.useQuery(undefined, {
    refetchOnWindowFocus: false,
  });

  return data?.email ? (
    <UserContext.Provider
      value={{ user: data?.email ?? '', menu: getToolingMenu() }}
    >
      <RouterProvider router={buildAppRouter()} />
    </UserContext.Provider>
  ) : (
    <Loader />
  );
};

LicenseInfo.setLicenseKey(
  import.meta.env.VITE_MUI_X_LICENSE_KEY || 'missing-key',
);

export const App = () => {
  const {
    error,
    isLoading: isLoadingAuth0,
    isAuthenticated,
    loginWithRedirect,
  } = useAuth0();

  if (isLoadingAuth0) {
    return <Loader />;
  } else if (error) {
    return (
      <ErrorPage
        status="Authentication Error"
        message={
          <div>
            For help please contact{' '}
            <a
              target="_blank"
              href="mailto:support@block.green"
              className="hover:no-underline"
              rel="noreferrer"
            >
              support@block.green
            </a>
          </div>
        }
      />
    );
  } else if (!isAuthenticated) {
    loginWithRedirect();
  } else {
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
        <TrpcProvider>
          <ReactQueryDevtools initialIsOpen={false} />
          <ThemeProvider theme={THEME_GLOBAL}>
            <SnackbarProvider
              hideIconVariant
              autoHideDuration={5_000}
              maxSnack={3}
              action={(snackbarId) => (
                <IconButton onClick={() => closeSnackbar(snackbarId)}>
                  <DeleteForeverIcon color="secondary" fontSize="small" />
                </IconButton>
              )}
            >
              <UserRouter />
            </SnackbarProvider>
          </ThemeProvider>
        </TrpcProvider>
      </LocalizationProvider>
    );
  }
};
