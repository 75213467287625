import { SqlDtoCompany } from '@fresh-stack/core-services';
import { wrapMutation, wrapQuery } from '@fresh-stack/frontend-commons';
import { isError, isSuccess } from '@fresh-stack/fullstack-commons';
import { zodResolver } from '@hookform/resolvers/zod';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LoadingButton } from '@mui/lab';
import {
  Accordion,
  Alert,
  Box,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import z from 'zod';
import { ROUTES } from '../../app/routes';
import { trpc } from '../../utils';

const SchemaNewCompany = z.object({
  name: z.string().min(2, { message: 'Invalid name.' }),
  homepageUrl: z
    .string()
    .refine(
      (value) => {
        if (!value) return true;
        // Regex to validate URL with or without protocol
        const pattern =
          /^(https?:\/\/)?[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/;
        return pattern.test(value);
      },
      {
        message: 'Please provide a valid url.',
      },
    )
    .optional(),
});

type NewCompany = z.infer<typeof SchemaNewCompany>;

const companyColumns: readonly GridColDef<SqlDtoCompany>[] = [
  { field: 'id', headerName: 'Id', width: 300 },
  { field: 'legalName', headerName: 'Legal name', width: 200 },
  { field: 'homepageUrl', headerName: 'Website', width: 150 },
  { field: 'createdAt', headerName: 'Created at', width: 200 },
];

export const AppCompanies = () => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>();
  const [successMessage, setSuccessMessage] = React.useState<
    string | undefined
  >();
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<NewCompany>({
    resolver: zodResolver(SchemaNewCompany),
  });

  const {
    result: companies,
    isFetching,
    refetch,
  } = wrapQuery(trpc.users.getCompanies.useQuery());
  const { mutateAsync: createCompany, isLoading } = wrapMutation(
    trpc.users.createCompany.useMutation(),
  );

  const saveCompany = async (input: NewCompany) => {
    const result = await createCompany(input);

    if (isError(result)) {
      setErrorMessage('Issue creating new company.');
    } else {
      setSuccessMessage('New company created: ' + result.right.id);
      reset();
      refetch();
    }
  };

  return (
    <Stack spacing={2}>
      {errorMessage && (
        <Alert
          variant="filled"
          severity="error"
          onClose={() => setErrorMessage(undefined)}
        >
          {errorMessage}
        </Alert>
      )}
      {successMessage && (
        <Alert
          variant="filled"
          severity="success"
          onClose={() => setSuccessMessage(undefined)}
        >
          {successMessage}
        </Alert>
      )}
      <form onSubmit={handleSubmit(saveCompany)}>
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography variant="h5" fontWeight={600}>
              Create new company
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container item spacing={2}>
              <Grid item md={6}>
                <TextField
                  id="legalName"
                  label="Legal name"
                  placeholder="Acme Co."
                  {...register('name')}
                  variant="filled"
                  color="primary"
                  fullWidth
                  size="small"
                  error={!!errors.name}
                  helperText={
                    errors.name ? <Box>{errors.name.message}</Box> : null
                  }
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  id="homepageUrl"
                  label="Website url"
                  placeholder="getribon.com"
                  {...register('homepageUrl')}
                  variant="filled"
                  color="primary"
                  fullWidth
                  size="small"
                  error={!!errors.homepageUrl}
                  helperText={
                    errors.homepageUrl ? (
                      <Box>{errors.homepageUrl.message}</Box>
                    ) : null
                  }
                />
              </Grid>
            </Grid>
          </AccordionDetails>
          <Box pl={2} pb={2}>
            <LoadingButton
              type="submit"
              variant="contained"
              color="primary"
              sx={{ width: 'fit-content' }}
              loading={isLoading}
            >
              Create company
            </LoadingButton>
          </Box>
        </Accordion>
      </form>
      {companies && isError(companies) && (
        <Alert severity="error">
          There was an issue loading the companies.
        </Alert>
      )}
      <Box sx={{ width: '100%', maxHeight: '55vh' }}>
        <DataGridPro
          sx={{
            boxShadow: 4,
            padding: 1,
          }}
          loading={isFetching}
          autoHeight
          rows={companies && isSuccess(companies) ? companies.right : []}
          columns={companyColumns}
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          pagination
          pageSizeOptions={[5, 10, 25]}
          onRowClick={(params) =>
            navigate(
              ROUTES.UserManagement.Company.replace(
                ':id',
                params.id.toString(),
              ),
            )
          }
        />
      </Box>
    </Stack>
  );
};
