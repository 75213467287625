import { wrapMutation } from '@fresh-stack/frontend-commons';
import { UserType, isSuccess } from '@fresh-stack/fullstack-commons';
import { ReqAddNewUserToCompany } from '@fresh-stack/router/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Grid,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';

import z from 'zod';
import { trpc } from '../../utils';

const SchemaNewUser = z.object({
  firstName: z.string().min(2, { message: 'Invalid name.' }),
  lastName: z.string().min(2, { message: 'Invalid name.' }),
  email: z.string().email(),
  role: z.union([z.literal('Admin'), z.literal('User')]),
});

export const InviteCompanyUser = ({
  companyId,
  onResult,
}: {
  readonly companyId: string;
  readonly onResult: (x: object) => void;
}) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
    control,
  } = useForm<ReqAddNewUserToCompany>({
    resolver: zodResolver(SchemaNewUser),
    defaultValues: {
      role: 'User',
    },
  });

  const { mutateAsync, isLoading } = wrapMutation(
    trpc.auth0.inviteUserToCompany.useMutation(),
  );

  const onInvite = async (data: ReqAddNewUserToCompany) => {
    const result = await mutateAsync({ ...data, companyId });
    if (isSuccess(result)) {
      reset();
      onResult({ successful: true });
    } else {
      onResult(result.left);
    }
  };

  return (
    <form onSubmit={handleSubmit(onInvite)}>
      <Grid
        padding={2}
        container
        item
        justifyContent={'stretch'}
        alignItems={'center'}
        xs={12}
        spacing={2}
      >
        <Grid item>
          <TextField
            id="firstName"
            label="First name"
            placeholder="Jane"
            {...register('firstName')}
            color="primary"
            fullWidth
            error={!!errors.firstName}
            helperText={
              errors.firstName ? <Box>{errors.firstName.message}</Box> : null
            }
          ></TextField>
        </Grid>
        <Grid item>
          <TextField
            id="lastName"
            label="Last name"
            placeholder="Smith"
            {...register('lastName')}
            color="primary"
            fullWidth
            error={!!errors.lastName}
            helperText={
              errors.lastName ? <Box>{errors.lastName.message}</Box> : null
            }
          ></TextField>
        </Grid>
        <Grid item>
          <TextField
            id="email"
            label="Email"
            placeholder="user@email.com"
            {...register('email')}
            color="primary"
            fullWidth
            error={!!errors.email}
            helperText={errors.email ? <Box>{errors.email.message}</Box> : null}
          ></TextField>
        </Grid>
        <Grid item>
          <Controller
            name="role"
            control={control}
            render={({ field }) => {
              return (
                <ToggleButtonGroup
                  size="small"
                  {...field}
                  color="primary"
                  onChange={(
                    _event: React.MouseEvent<HTMLElement>,
                    value: UserType | undefined,
                  ) => {
                    if (value) setValue(field.name, value);
                  }}
                  exclusive
                  aria-label="text alignment"
                >
                  <ToggleButton value="User">User</ToggleButton>
                  <ToggleButton value="Admin">Admin</ToggleButton>
                </ToggleButtonGroup>
              );
            }}
          ></Controller>
        </Grid>
        <Grid item>
          <LoadingButton
            sx={{ height: 40, width: 'fit-content' }}
            loading={isLoading}
            variant="contained"
            type="submit"
          >
            Invite
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};
