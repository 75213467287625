// src/auth/auth0-provider-with-history.js

import { Auth0Provider } from '@auth0/auth0-react';
import * as qs from 'query-string';
import React from 'react';
import { Auth0ProviderConfig } from '../types/auth0';

export const CustomAuth0Provider = ({
  config,
  children,
}: {
  readonly config: Auth0ProviderConfig;
  readonly children: React.ReactElement;
}) => {
  const {
    invitation,
  }: {
    readonly invitation?: string;
  } = qs.parse(window.location.search);

  const redirectUri = window.location.origin;

  return (
    <Auth0Provider
      {...{
        environment: config.environment,
        domain: config.domain,
        clientId: config.clientId,
        authorizationParams: {
          redirect_uri: redirectUri,
          organization: config.organization,
          invitation,
          audience: config.audience,
        },
      }}
    >
      {children}
    </Auth0Provider>
  );
};
