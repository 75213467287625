import { IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

type CustomHoverIconButtonProps = {
  readonly defaultColor?: string;
  readonly hoverColor?: string;
  readonly disabledColor?: string;
};

export const CustomHoverIconButton = styled(IconButton, {
  shouldForwardProp: (prop) =>
    prop !== 'defaultColor' &&
    prop !== 'hoverColor' &&
    prop !== 'disabledColor',
})<CustomHoverIconButtonProps>(
  ({
    defaultColor = 'gray',
    hoverColor = 'red',
    disabledColor = 'lightgray',
  }) => ({
    color: defaultColor,
    '&:hover': {
      color: hoverColor,
    },
    '&.Mui-disabled': {
      color: disabledColor,
    },
  }),
);
