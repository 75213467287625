import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { Outlet, ScrollRestoration } from 'react-router-dom';
import { DRAWER_WIDTH, ToolingSideMenu } from './components/ToolingSideMenu';

interface LayoutProps {
  readonly isErrorPage?: boolean;
  readonly children?: readonly JSX.Element[] | JSX.Element;
}

export const AppLayout = ({ children, isErrorPage = false }: LayoutProps) => {
  return (
    <Box sx={{ flexGrow: 1 }} display="flex">
      <CssBaseline />

      <Box width={DRAWER_WIDTH}>
        <ToolingSideMenu />
      </Box>

      <Box component="main" display="flex" sx={{ p: 3 }} width={'100%'}>
        {isErrorPage ? (
          children
        ) : (
          <Box>
            <Outlet />
          </Box>
        )}
      </Box>
      <ScrollRestoration />
    </Box>
  );
};
