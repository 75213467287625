import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { ReactElement } from 'react';

export const ConfirmationModal = ({
  question,
  handleApprove,
  handleCancel,
}: {
  readonly question: string | ReactElement;
  readonly handleApprove: () => void;
  readonly handleCancel: () => void;
}) => {
  return (
    <Dialog open={!!question} onClose={handleCancel}>
      <DialogTitle id="alert-dialog-title">{'Confirm action'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {question}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleApprove}>Yes</Button>
        <Button onClick={handleCancel} autoFocus>
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
};
